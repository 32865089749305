import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { alertService } from "@/services/alert/alert.service";
import { facilityInventoryService } from "@/services/facility/inventory/inventory.service";
import config from "@/config/app.config";
import moment from "moment";
import allLocales from "@fullcalendar/core/locales-all";
import { EventBus } from "@/event-bus";

export default {
    components: {
        FullCalendar, // make the <FullCalendar> tag available
    },
    data() {
        return {
            data: {
                airport_id: config.airport_id,
                online_status: 1,
                listing_order: 1,
                parking_type: 1,
                // inventory: {
                //     capacity: [],
                //     stop_booking: [],
                // },
                reservable_capacities: [],
                stop_booking: [],
                car_room_inventory: "",
                car_room: [],
            },
            error: null,
            config: {},
            calendarOptions: {
                locales: allLocales,
                locale: this.$i18n.locale,
                plugins: [dayGridPlugin, interactionPlugin],
                initialView: "dayGridMonth",
                selectable: true,
                selectOverlap: false,
                select: this.handleDateSelect,
                eventClick: this.handleEventClick,
                eventOverlap: false,
                events: [],
                eventContent: this.reRenderCalendar,
            },
            days: [],
            input: 0,
            calendar: {},
            calendarApi: null,
            stopBooking: [],
            yearMonth: moment().format("Y-MM"),
            listing_order_count: [],
        };
    },
    watch: {
        parkingType() {
            if (this.$route.params.id) {
                return;
            }

            this.data.car_room = [];
            this.calendarOptions.events = [];
            this.days = [];
            this.data.car_room_inventory = "--";
            this.data.reservable_capacities = [];
        },
    },
    async mounted() {
        this.calendarApi = this.$refs.fullCalendar.getApi();

        if (this.$route.params.id) {
            await this.show(this.$route.params.id);
        }

        await this.parkingList();

        EventBus.$on("changeLocal", () => {
            this.calendarOptions.locale = this.$i18n.locale;
        });

        this.data.car_room_inventory = "--";
    },
    computed: {
        dates() {
            return this.days.map((day) => day.date);
        },
        attributes() {
            return this.dates.map((date) => ({
                highlight: true,
                dates: date,
            }));
        },
        calendars() {
            return document.getElementsByClassName("vc-title");
        },
        parkingType() {
            return this.data.parking_type;
        },
        fields() {
            return [
                { key: "no", label: "#", thClass: "col-1" },
                {
                    key: "name",
                    label: this.$t("facility.inventory.name"),
                    thClass: "col-3",
                },
                {
                    key: "next_reservation",
                    label: this.$t("facility.inventory.next-reservation"),
                    thClass: "col-3",
                },
                {
                    key: "operation",
                    label: this.$t("facility.inventory.operation"),
                    thClass: "col-3",
                },
            ];
        },
        showCarRoom() {
            return this.data.car_room.length;
        },
        remainingMonth() {
            let month = moment().format("M");
            let year = moment().format("Y");

            let remainingMonth = [];

            for (let index = parseInt(month); index <= 12; index++) {
                remainingMonth.push({ month: index, year: parseInt(year) });
            }

            return remainingMonth;
        },
    },
    methods: {
        handleDateSelect(selectInfo) {
            if (this.data.parking_type == 2) {
                return;
            }

            this.calendar = selectInfo;
            if (
                moment(new Date()).format("Y-MM-DD") >
                moment(this.calendar.startStr).format("Y-MM-DD")
            ) {
                return;
            }

            this.$bvModal.show("add-input");
        },

        handleEventClick(clickInfo) {
            if (this.data.parking_type == 2) {
                return;
            }

            this.calendar = clickInfo.event;
            if (
                moment(new Date()).format("Y-MM-DD") >
                moment(this.calendar.startStr).format("Y-MM-DD")
            ) {
                return;
            }

            this.$bvModal.show("add-input");
        },

        addInput(e) {
            e.preventDefault();
            if (
                this.calendarOptions.events
                    .map((i) => i.start)
                    .includes(this.calendar.startStr)
            ) {
                let index = this.calendarOptions.events
                    .map((i) => i.start)
                    .indexOf(this.calendar.startStr);
                this.calendarOptions.events[index].title = parseInt(this.input);
                this.data.reservable_capacities[index].capacity = parseInt(
                    this.input
                );
            } else {
                this.calendarOptions.events.push({
                    start: this.calendar.startStr,
                    end: this.calendar.startStr,
                    title: parseInt(this.input),
                });

                this.data.reservable_capacities.push({
                    date: this.calendar.startStr,
                    capacity: parseInt(this.input),
                });
            }

            this.input = 0;
            this.days = [];
            console.log('Single input', this.data)
            this.$bvModal.hide("add-input");
        },

        async store$() {
            this.error = null;
            if (this.$route.params.id) {
                this.update();
                return;
            }

            var callback = async () => {
                await alertService.loading();

                await facilityInventoryService
                    .store(this.data)
                    .then((response) => {
                        alertService.close();
                        this.$router.go(-1);
                    })
                    .catch((error) => {
                        this.error = error.message;
                        alertService.close();
                    });
            };
            alertService.confirmation(
                callback,
                this.$t("facility.global.confirmation"),
                this.$t("facility.global.are-you-sure")
            );
        },

        async update$() {
            this.error = null;

            var callback = async () => {
                await facilityInventoryService
                    .update(this.$route.params.id, this.data)
                    .then(async () => {
                        alertService.success(
                            this.$t("facility.global.success"),
                            this.$t("facility.inventory.record-updated")
                        );
                        await this.show(this.$route.params.id);
                    })
                    .catch((error) => {
                        this.error = error.message;
                    });
            };
            alertService.confirmation(
                callback,
                this.$t("facility.global.confirmation"),
                this.$t("facility.global.are-you-sure")
            );
        },

        async show(id) {
            var response = await facilityInventoryService.show(id);

            this.data = response.data;
            this.data.car_room_inventory = response.data.car_room.length;

            this.calendarOptions.events = [];
            response.data.reservable_capacities.forEach((inventory) => {
                this.calendarOptions.events.push({
                    start: inventory.date,
                    end: inventory.date,
                    title: inventory.capacity,
                });
            });
            this.stopBooking = [];
            response.data.stop_booking.forEach((inventory) => {
                this.calendarOptions.events.push({
                    start: inventory.date,
                    end: inventory.date,
                    title: inventory.capacity,
                });

                this.stopBooking.push(inventory.date);
            });
        },

        onDayClick(day) {
            if (
                moment(new Date()).format("Y-MM-DD") >
                moment(day.id).format("Y-MM-DD")
            ) {
                return;
            }

            const idx = this.days.findIndex((d) => d.id === day.id);
            if (idx >= 0) {
                this.days.splice(idx, 1);
            } else {
                this.days.push({
                    id: day.id,
                    date: day.date,
                });
            }

            if (this.stopBooking.includes(day.id)) {
                let index = this.stopBooking.indexOf(day.id);
                this.stopBooking.splice(index, 1);

                if (
                    this.data.stop_booking
                        .map((i) => i.date)
                        .includes(day.id)
                ) {
                    let key = this.data.stop_booking
                        .map((i) => i.date)
                        .indexOf(day.id);
                    this.data.reservable_capacities.push(
                        this.data.stop_booking[index]
                    );
                    this.data.stop_booking.splice(key, 1);

                    $(`[data-date='${day.id}']`).css({ background: "inherit" });
                }
            }
        },

        async batchInput() {
            if (
                this.data.car_room_inventory != "--" &&
                this.data.parking_type == 2
            ) {
                this.input = this.data.car_room_inventory;
            }

            if (this.data.parking_type == 2) {
                this.calendarOptions.events = [];
            }

            this.days.forEach((day) => {
                if (
                    this.calendarOptions.events
                        .map((i) => i.start)
                        .includes(day.id) &&
                    !this.stopBooking.includes(day.id)
                ) {
                    let index = this.calendarOptions.events
                        .map((i) => i.start)
                        .indexOf(day.id);
                    this.calendarOptions.events[index].title = parseInt(
                        this.input
                    );
                    this.data.reservable_capacities[index].capacity = parseInt(
                        this.input
                    );
                } else {
                    this.calendarOptions.events.push({
                        start: day.id,
                        end: day.id,
                        title: parseInt(this.input),
                    });

                    this.data.reservable_capacities.push({
                        date: day.id,
                        capacity: parseInt(this.input),
                    });
                }
            });
            this.$refs["batch-modal"].hide();
            this.input = 0;
            console.log('Batch input', this.data)
        },

        async batchStop() {
            this.days.forEach((day) => {
                if (!this.stopBooking.includes(day.id)) {
                    this.stopBooking.push(day.id);
                }

                if (
                    this.data.reservable_capacities
                        .map((i) => i.date)
                        .includes(day.id)
                ) {
                    let index = this.data.reservable_capacities
                        .map((i) => i.date)
                        .indexOf(day.id);
                    this.data.stop_booking.push(
                        this.data.reservable_capacities[index]
                    );
                    this.data.reservable_capacities.splice(index, 1);
                }

                if (
                    !this.data.stop_booking
                        .map((i) => i.date)
                        .includes(day.id)
                ) {
                    this.data.stop_booking.push({
                        date: day.id,
                        capacity: 0,
                    });

                    this.calendarOptions.events.push({
                        end: day.id,
                        start: day.id,
                        title: 0,
                    });
                }
            });
            let calendarApi = this.$refs.fullCalendar.getApi();
            calendarApi.render();
            this.$refs["batch-modal-stop"].hide();
            console.log('Batch stop', this.data)
        },

        reRenderCalendar(arg) {
            if (this.stopBooking.includes(arg.event.startStr)) {
                $(`[data-date='${arg.event.startStr}']`).css({
                    background: "#ffcccb",
                });
            }

            return {
                html: `<strong><span :style="color: ${arg.event.textColor} !important" class="text-primary event-value">0</span>${arg.event.title}</strong>`,
            };
        },

        stopBookingBtn() {
            this.days = [];
            this.stopBooking.forEach((date) => {
                let idx = this.days.findIndex((d) => d.id === date);
                if (idx == -1) {
                    this.days.push({
                        date: new Date(date),
                        id: date,
                    });
                }
            });
        },
        selecAll() {
            let daysInMonth = moment(this.yearMonth, "YYYY-MM").daysInMonth();

            for (let index = 0; index < daysInMonth; index++) {
                let dates =
                    moment(this.yearMonth, "YYYY-MM").format("Y") +
                    "-" +
                    moment(this.yearMonth, "YYYY-MM").format("MM") +
                    `-${index + 1}`;
                let formatedDate = moment(dates).format("Y-MM-DD");
                if (
                    !this.stopBooking.includes(formatedDate) &&
                    !this.days.map((i) => i.id).includes(formatedDate)
                ) {
                    if (
                        moment(new Date()).format("Y-MM-DD") <=
                        moment(dates).format("Y-MM-DD")
                    ) {
                        this.days.push({
                            date: new Date(dates),
                            id: formatedDate,
                        });
                    }
                }
            }
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                // 46 is dot
                $event.preventDefault();
            }
        },
        changeMonth(data) {
            this.yearMonth = moment(`${data.year}-${data.month}`).format(
                "Y-MM"
            );
        },
        setCarRoom() {
            if (this.data.car_room_inventory != "--") {
                this.data.car_room = [];
            }

            this.data.car_room = [];

            for (
                let index = 1;
                index <= this.data.car_room_inventory;
                index++
            ) {
                this.data.car_room.push({
                    name: 100 + index,
                    operation: this.data.parking_type == 1 ? null : 1,
                });
            }

            if (this.data.parking_type == 1) {
                return;
            }

            this.remainingMonth.forEach((date) => {
                this.changeMonth(date);
                this.selecAll();
            });

            this.calendarOptions.events.forEach((item) => {
                item.title =
                    this.data.car_room_inventory == "--"
                        ? 0
                        : this.data.car_room_inventory;
            });

            // Clearing data capacity to avoid duplication
            this.data.reservable_capacities = [];
            this.batchInput();
        },
        openBatchInput() {
            if (this.data.parking_type == 1) {
                this.days = [];
            }
        },

        async parkingList() {
            alertService.loading();
            let response = await facilityInventoryService.parkingList();

            if (response.data.data.length > 0) {
                if (this.$route.params.id) {
                    this.listing_order_count = Array.from(
                        { length: response.data.data.length },
                        (v, k) => k + 1
                    );
                } else {
                    this.listing_order_count = Array.from(
                        { length: response.data.data.length + 1 },
                        (v, k) => k + 1
                    );
                    this.data.listing_order = this.listing_order_count.length
                }
            } else {
                this.listing_order_count = [1]
                this.data.listing_order = 1
            }
            alertService.close();
        },
    },
};
